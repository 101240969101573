import logo from "./logo.png"
import jenkins from "./jenkins.png"

export default function SAP() {
    return (
        <div className="portfolio-items">
            <div className="company">SAP</div>
            <div className="date">CI/CD Pipeline Developer</div>
            <div className="date"><i>January - August 2023</i></div>
            <img className="guelph-logo" src={logo} alt="University of Guelph"/>            
            <div className="description">SAP is a German enterprise software company with over 100 development locations worldwide. They produce a myriad of business applications, spanning from finances to HR. I worked in the Waterloo office, which has a specific focus on cloud business applications.</div>
            <div className="portfolio-header">Job Description</div>
            <img className="jenkins" src={jenkins} alt="Jenkins Logo"/>            
            <div className="description">At SAP I was a part of the Client Interfaces team, which was responsible for writing and maintaining the drivers that programmers and customers use to access a HANA cloud instance. As a CI/CD Pipeline Developer, my main responsibility was to create, refactor and maintain our automated testing pipeline that was responsible for many tasks: build and test our repository against different combinations of server and client, automatically scan code when it is pushed to our repository for compliance, code style and security or performance changes, perform various automated tasks like testing updated versions of packages or updating documentation pages, etc. Jenkins was the automation server, which executed groovy code that acted as pipeline definitions. We had drivers maintained for several languages (Java, C++, Python, Ruby, Golang, Typescript, Node, etc.) which introduced a significant amount of maintenance for the team. Our manager was adamant in asking the following question: can we automate that? Which was a great investment to save developer time in the future. <br /><br />
                Our team wanted to take advantage of the latest and greatest Jenkins pipeline technologies, so I created an updated instance of a Jenkins server and began refactoring our old, GUI-defined pipelines into Groovy-defined pipelines. This involved connecting and working with 10+ Linux agents via SSH and configuring permissions, packages, connections etc. </div>
            <div className="portfolio-header">Learning Goals</div>
            {/* <img className="alignment" src={alignment} alt="Alignment Example"/>             */}
            <div className="description">Attending presentations with a charismatic and clear speaker is always a pleasure, and given I was to be presenting often in this role, I wanted to specifically focus on improving my public speaking and presenting skills. I first attended presentations from a variety of people at SAP, picking up on what makes a speaker interesting to listen to, and how I can implement those techniques into my presentations. I worked on creating simple and engaging visuals, as well as speaking slowly, and trying to reduce “fluff” in my words. Luckily, I was given many opportunities to practice. Weekly standups, sprint retrospectives and even office-wide events all tested my abilities, and while I believe public speaking is a skill you can always improve on (and I would like to continue to do so), I found myself being a lot less worried about presentations at the end of my work term. <br /><br />
                My next learning goal was to increase my depth and breadth of DevOps technologies. Surprisingly, this learning was much more conceptual than technological. I learned and used many tools and languages for our DevOps automation, but the real problems arise with the way developers interact with them. How can I create a straightforward experience for a developer that still provides all the necessary information? The biggest part of this had me reading best practices, and trying to introduce them into our DevOps stack. Due to this research, I ended up implementing a system that allowed pipelines to be checked into source control as code in our repository, instead of in the GUI of our automation server itself. <br /><br />
                My final learning goal was to learn and implement secure and stable practices. In school, I aim for my programs to succeed every time and am often not required to emphasize the security or stability of a program. At work, however, this matters immensely, and something my highly skilled team was great at making a high priority. Through reviewing others' code, sitting in on design discussions and having my code reviewed, I learned important lessons about programming in the workplace. I find the tenants are similar to that of a skeptic: question everything, implications, assumptions, and plan for mistakes. Especially when it comes to pipelines, handling failure smoothly and notifying the correct persons automatically is of utmost importance.</div>
            <div className="portfolio-header">Developing for Developers</div>
            {/* <img className="alignment" src={board} alt="KV260 Board"/>    */}
            <div className="description">Our team was in a unique position, as we worked on drivers and APIs that were made for other developers, both inside and outside of the company. We often had to ask: How would I want to use this? Likewise, as my work similarly affected our team, I could ask myself the same question. One lesson that came from this was the importance of collaborative programming and design, how you might use a particular feature vs. how someone else might, which means it's important to implement a solution that can be taken advantage of in many different ways.</div>
            <div className="portfolio-header">Conclusion</div>
            <div className="description">My time at SAP was full of challenges and interesting solutions. I want to thank SAP for making this an amazing internship experience, and especially my supervisor Dan Cummins, my manager David Brandow, the intern lead Lindsay Verhuel and finally my fellow members of the intern team!</div>
        </div>
    )
}